import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogChangeStatusComponent } from '../../../generales/dialog-change-status/dialog-change-status.component';
import { DialoSaveUpdateDetalleCuadranteComponent } from '../../_shared/dialo-save-update-detalle-cuadrante/dialo-save-update-detalle-cuadrante.component';
import { DialoSaveUpdateDetalleCalculoComponent } from '../../_shared/dialo-save-update-detalle-calculo/dialo-save-update-detalle-calculo.component';
import { DialogSaveUpdateDimensionComponent } from '../../_shared/dialog-save-update-dimension/dialog-save-update-dimension.component';
import { DialogSaveUpdateCompetenciaComponent } from '../../_shared/dialog-save-update-competencia/dialog-save-update-competencia.component';
import { DialogSaveUpdatePreguntaComponent } from '../../_shared/dialog-save-update-pregunta/dialog-save-update-pregunta.component';
import { DialogUpdatePasswordComponent } from '../../_shared/dialog-update-password/dialog-update-password.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private dialog: MatDialog
  ) { }


  _enable_disable(title: string = '', description: string = '', accion: string = '') {
    return this.dialog.open(DialogChangeStatusComponent, {
      data: { title, description, accion},
      width: '500px',
      disableClose: true
    });
  }

  _add_detalle_cuadrante(title: string = '', item: any) {
    return this.dialog.open(DialoSaveUpdateDetalleCuadranteComponent, {
      data: { title, item},
      width: '600px',
      disableClose: true
    });
  }

   _add_detalle_calculo(title: string = '', item: any) {
    return this.dialog.open(DialoSaveUpdateDetalleCalculoComponent, {
      data: { title, item},
      width: '700px',
      disableClose: true
    });
  }

  _add_dimension(title: string = '', item: any) {
    return this.dialog.open(DialogSaveUpdateDimensionComponent, {
      data: { title, item},
      width: '800px',
      disableClose: true
    });
  }

  _add_competencia(title: string = '', Dimension, item: any) {
    return this.dialog.open(DialogSaveUpdateCompetenciaComponent, {
      data: { title, Dimension, item},
      width: '800px',
      disableClose: true
    });
  }

  _add_pregunta(title: string = '', Competencia, item: any) {
    return this.dialog.open(DialogSaveUpdatePreguntaComponent, {
      data: { title, Competencia, item},
      width: '650px',
      disableClose: true
    });
  }

  _update_password(title: string = '') {
    return this.dialog.open(DialogUpdatePasswordComponent, {
      data: { title },
      width: '600px',
      disableClose: true
    });
  }


  



}
